import PATHS from '../constants';

function fetchContact() {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(PATHS.CONTACT, {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchContact;