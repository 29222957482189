import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import fetchContact from '../fetch/contact';

function plainNumber(number) {
    return number.replace(/\D/g,'');
}

function capitalize(string) {
    return `${string[0].toUpperCase()}${string.slice(1)}`;
}
class Contact extends Component{
    constructor () {
        super()
        this.state = {
          data: null,
          isOpen: false
        }
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

      getData(){
        fetchContact()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){
        return(
            <div>
                <NavBar/>
                {   this.state.data && 
                    <div>
                        <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: this.state.data.portada && `url(${this.state.data.portada.data.full_url})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="page-banner text-center">
                                        <h1>{this.state.data.titulo}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-wrapper section-space--inner--120">
                    <div className="conact-section">
                        <div className="container">
                        {   this.state.data.mapa &&
                            <div className="row section-space--bottom--50">
                                <div className="col">
                                    <div className="contact-map">
                                    <iframe title="google-map" src={this.state.data.mapa} allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        <div className="row">
                            <div className="col-lg-4 col-12">
                            <div className="contact-information">
                                <h3>{this.state.data.titulo_informacion || 'Información de contacto'}</h3>
                                <ul>
                                    <li>
                                        <span className="icon"><i className="ion-android-map" /></span>
                                        <span className="text"><span>{this.state.data.direccion}</span></span>
                                    </li>
                                    <li>
                                        <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                        <span className="text">
                                            { this.state.data.telefono_1 && <a href={`tel:${plainNumber(this.state.data.telefono_1)}`}>{this.state.data.telefono_1}</a>}
                                            { this.state.data.telefono_2 && <a href={`tel:${plainNumber(this.state.data.telefono_2)}`}>{this.state.data.telefono_2}</a>}
                                            { this.state.data.telefono_3 && <a href={`tel:${plainNumber(this.state.data.telefono_3)}`}>{this.state.data.telefono_3}</a>}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon"><i className="ion-ios-email-outline" /></span>
                                        <span className="text">
                                            { this.state.data.email_1 && <a href={`mailto:${this.state.data.email_1}`}>{this.state.data.email_1}</a>}
                                            { this.state.data.email_2 && <a href={`mailto:${this.state.data.email_2}`}>{this.state.data.email_2}</a>}
                                        </span>
                                    </li>
                                    {   this.state.data.waze_link &&
                                        <li>
                                            <span className="icon"><i className="ion-ios-navigate-outline" /></span>
                                            <span className="text"><a href={this.state.data.waze_link}>{this.state.data.waze_texto || 'Ubicanos en Waze!'}</a></span>
                                        </li>
                                    }
                                </ul>
                            </div>
                            </div>
                            <div className="col-lg-8 col-12">
                            <div className="contact-form">
                                <h3>{this.state.data.titulo_formulario || 'Contactate con nosotros'}</h3>
                                <form id="contact-form">
                                <div className="row row-10">
                                    <div className="col-md-6 col-12 section-space--bottom--20"><input name="nombre" type="text" placeholder="Nombre" /></div>
                                    <div className="col-md-6 col-12 section-space--bottom--20"><input name="email" type="email" placeholder="Email" /></div>
                                    <div className="col-md-6 col-12 section-space--bottom--20"><input name="telefono" type="text" placeholder="Teléfono" /></div>
                                    <div className="col-md-6 col-12 section-space--bottom--20">
                                        { this.state.data.campo_extra_1 && 
                                        <input name={((this.state.data.campo_extra_1).toLowerCase()).replace(' ', '_')} type="text" placeholder={capitalize(this.state.data.campo_extra_1)} /> 
                                        }
                                    </div>
                                    <div className="col-md-6 col-12 section-space--bottom--20">
                                        { this.state.data.campo_extra_2 && 
                                        <input name={((this.state.data.campo_extra_2).toLowerCase()).replace(' ', '_')} type="text" placeholder={capitalize(this.state.data.campo_extra_2)} /> 
                                        }
                                    </div>
                                    <div className="col-12"><textarea name="message" placeholder="Mensaje" defaultValue={""} /></div>
                                    <div className="col-12"><button>Enviar</button></div>
                                </div>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Contact section end*/}
                    </div>
                </div>
                }
                {/*====================  End of content page content  ====================*/}
                
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Contact;