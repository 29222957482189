const COLLECTIONS_URL = `https://api.automation-power.com/public/autpow/items`;

const PATHS = {
    HOME: `${COLLECTIONS_URL}/home?fields=equipo_imagen.data,logo.data,logo_blanco.data,*`,
    ABOUT_US: `${COLLECTIONS_URL}/about_us?fields=portada.data,imagen_video.data,fondo_contador.data,imagen_1.data,imagen_2.data,*`,
    ABOUT_US_FEATURES: `${COLLECTIONS_URL}/about_us_features?fields=icono.data,*`,
    ABOUT_US_COUNTER: `${COLLECTIONS_URL}/about_us_contador?fields=icono.data,*`,
    ABOUT_US_TEAM: `${COLLECTIONS_URL}/team?fields=imagen.data,*`,
    HOME_BLOG: `${COLLECTIONS_URL}/blog?fields=portada.data,*`,
    HOME_JOBS: `${COLLECTIONS_URL}/open_jobs?filter[estado][nempty]=true`,
    HOME_SLIDER: `${COLLECTIONS_URL}/home_slider?fields=imagen.data,*`,
    HOME_ABOUT_US: `${COLLECTIONS_URL}/home_about_us?fields=imagen.data,*`,
    HOME_BRANDS: `${COLLECTIONS_URL}/home_brands?fields=imagen.data,*`,
    HOME_PROJECTS: `${COLLECTIONS_URL}/projects?fields=portada.data,*`,
    HOME_SERVICES: `${COLLECTIONS_URL}/services?fields=portada.data,*`,
    HOME_TESTIMONIAL: `${COLLECTIONS_URL}/testimonial?fields=foto.data,fondo.data,*`,
    POST: id => `${COLLECTIONS_URL}/blog/${id}?fields=portada.data,*`,
    BLOG: `${COLLECTIONS_URL}/blog?fields=portada.data,*`,
    BLOG_PORTADA: `${COLLECTIONS_URL}/blog_portada?fields=portada.data,*`,
    BLOG_TAGS: `${COLLECTIONS_URL}/blog_tags`,
    BLOG_CATEGORIES: `${COLLECTIONS_URL}/blog_categories`,
    BLOG_IMAGES: id => `${COLLECTIONS_URL}/blog_images?filter[post][eq]=${id}&fields=imagen.data`,
    SERVICE: `${COLLECTIONS_URL}/service?fields=portada.data,*`,
    SERVICES: `${COLLECTIONS_URL}/services?fields=portada.data,*`,
    SERVICE_DETAILS: id => `${COLLECTIONS_URL}/services/${id}?fields=portada.data,*`,
    SERVICE_IMAGES : id => `${COLLECTIONS_URL}/services_images?filter[servicio][eq]=${id}&fields=imagen.data`,
    PROJECTS: `${COLLECTIONS_URL}/projects?fields=portada.data,*`,
    PROJECT: `${COLLECTIONS_URL}/project?fields=portada.data,*`,
    PROJECT_DETAILS: id => `${COLLECTIONS_URL}/projects/${id}?fields=portada.data,*`,
    PROJECT_IMAGES: id => `${COLLECTIONS_URL}/projects_images?filter[project][eq]=${id}&fields=imagen.data`,
    CONTACT: `${COLLECTIONS_URL}/contact_us?fields=portada.data,*`,
};

export default PATHS;