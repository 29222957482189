import PATHS from '../constants';

function fetchBlogData(id) {
    const url = PATHS.POST(id);
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(url, {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchBlogData;