import PATHS from '../constants';

function fetchServiceImages(id) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(PATHS.SERVICE_IMAGES(id), {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchServiceImages;